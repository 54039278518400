<template>
    <div>
        <ArrowDownTitle background-color-class="bg-white">Choose a document type</ArrowDownTitle>

        <ActivityChooser />
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import ArrowDownTitle from '../widgets/arrow-down-title.vue'
import ActivityChooser from './ActivityChooser.vue'

export default defineComponent({
    name: 'DocumentTypeChooser',
    components: {
        ArrowDownTitle,
        ActivityChooser,
    },
})
</script>
