<template>
    <div class="text-center">
        <div class="arrow-down-title">
            <slot></slot>
            <div class="arrow-down-segment" :class="backgroundColor"></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ArrowDownTitle',
    props: {
        backgroundColorClass: {
            type: [String, Array, Object, null],
            default: null,
        },
    },
    computed: {
        backgroundColor() {
            if (this.backgroundColorClass) {
                return this.backgroundColorClass
            } else {
                return 'bg-white'
            }
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

.arrow-down-title {
    text-align: center;
    animation-name: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: both;
    opacity: 0;
    font-size: 30px;
    font-weight: 400;
    padding: 15px 0 10px;
    color: $gray-600;
    border-bottom: 3px solid $gray-600;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    margin: 15px auto;

    > .arrow-down-segment {
        border-bottom: 3px solid $gray-600;
        border-right: 3px solid $gray-600;
        position: absolute;
        height: 20px;
        width: 20px;
        bottom: -12px;
        left: calc(50% - 8.5px);
        transform: rotate(45deg);
    }
}
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
</style>
